	<template>
		<v-row>
			<v-dialog v-model="dialogVendas" :max-width="mostrarColuna ? '1180' : '900'" scrollable>
				<v-card>
					<v-card-title class="primary white--text">
						Ranking diário Vendas - GESTOR | {{ busca.dtdia.split('-').reverse().join('/') }}
						<v-spacer />
						<v-img max-width="220" src="../../assets/icons/logo_min.png" />
					</v-card-title>
					<v-divider />
					<v-card-text class="black--text pt-5 mb-n3">
						<v-simple-table dense style="border: 1px solid #023449">
							<thead>
								<tr class="primary" style="height: 50px;">
									<th class="white--text text-subtitle-2">RK</th>
									<th class="white--text text-subtitle-2 pr-0">Filial</th>
									<th class="white--text text-center text-subtitle-2 d-flex pl-2">
                    <!-- Tooltip para alternar a visibilidade da coluna -->
                    <v-tooltip color="blue-grey" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="float-left mr-1 mt-1" icon fab x-small v-bind="attrs"
                               @click="mostrarColuna = !mostrarColuna" v-on="on">
                          <v-icon color="white" size="14">
                            {{ mostrarColuna ? 'mdi-eye-off' : 'mdi-eye' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="white--text">
												{{ mostrarColuna ? "Ocultar Venda (R$)" : "Mostrar Venda (R$)" }}
											</span>
                    </v-tooltip>
                    <span class="mt-1">Realizado diário</span>
                  </th>
                  <v-fade-transition hide-on-leave>
                    <th v-show="mostrarColuna" class="white--text text-center text-subtitle-2">
                      Realizado Diário (R$)</th>
                  </v-fade-transition>
									<th class="white--text text-center text-subtitle-2">Realizado acumulado</th>
                  <v-fade-transition hide-on-leave>
                    <th v-show="mostrarColuna" class="white--text text-center text-subtitle-2">
                      Realizado acumulado (R$)</th>
                  </v-fade-transition>
									<th class="white--text text-center text-subtitle-2">Margem diária</th>
									<th class="white--text text-center text-subtitle-2">Margem acumulada</th>
									<th class="white--text text-center text-subtitle-2">% Var. A.A</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(u, i) in dialogDados" :key="i" class="font-weight-bold">
									<td style="height: 23px !important;">{{ i + 1 }}</td>
									<td style="height: 23px !important;" class="text-truncate">{{ u.filial }}</td>
									<td style="height: 23px !important;" :class="realizadoCor(u.perrealizado)"
										class="text-center text-truncate pl-13">{{
											u.perrealizado.toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" style="height: 23px !important;" class="text-center" :class="realizadoCor(u.perrealizado)">{{
                        u.realizadodia |
                            formataDinheiro }}</td>
                  </v-fade-transition>
									<td style="height: 23px !important;" :class="realizadoCor(u.perealizadoac)"
										class="text-center">{{
											u.perealizadoac.toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" style="height: 23px !important;" :class="realizadoCor(u.perealizadoac)" class="text-center">
                      {{ u.realizadoacumuladoac | formataDinheiro }}</td>
                  </v-fade-transition>
									<td style="height: 23px !important;" :class="margemCor(u.margemdiaria)"
										class="text-center">{{
											u.margemdiaria.toFixed(2) }} %</td>
									<td style="height: 23px !important;" :class="margemCor(u.margemacumuladaac)"
										class="text-center">{{
											u.margemacumuladaac.toFixed(2) }} %</td>
                  <td style="height: 23px !important;" :class="variacaoCor(u.variacao)"
                      class="text-center text-truncate">{{ u.variacao.toFixed(2) }} %</td>
								</tr>
							</tbody>
							<tfoot v-if="dashboard.rankingfooter">
								<tr class="primary white--text font-weight-bold">
									<td></td>
									<td>TOTAL</td>
									<td class="text-center pl-13">{{ dashboard.rankingfooter.filter(v => v.regional ==
										'Todas')[0].perrealizado }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" class="text-center">{{ dashboard.rankingfooter.filter(v => v.regional ==
                        'Todas')[0].realizadodia | formataDinheiro }}</td>
                  </v-fade-transition>
									<td class="text-center">{{ dashboard.rankingfooter.filter(v => v.regional ==
										'Todas')[0].perealizadoac }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" class="text-center">{{ dashboard.rankingfooter.filter(v => v.regional ==
                        'Todas')[0].realizadoacumuladoac | formataDinheiro }}</td>
                  </v-fade-transition>
									<td class="text-center">{{ dashboard.rankingfooter.filter(v => v.regional ==
										'Todas')[0].margemdiaria }} %</td>
									<td class="text-center">{{ dashboard.rankingfooter.filter(v => v.regional ==
										'Todas')[0].margemacumuladaac }} %</td>
                  <td class="text-center">{{ ((dashboard.rankingfooter.filter(v => v.regional ==
                      'Todas')[0].realizadoacumuladoac / dashboard.anoanterior -1)*100).toFixed(2) }} %</td>
								</tr>
							</tfoot>
						</v-simple-table>
					</v-card-text>
					<v-divider />
					<v-card-actions class="pa-3">
						<v-btn :loading="carregando" block color="primary" elevation="0"
							@click="dialogVendas = false">Fechar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialogFaturamento" :max-width="mostrarColuna ? '1100' : '900'" scrollable>
				<v-card>
					<v-card-title class="primary white--text">
						<span>Ranking diário Faturamento DRE - GESTOR | {{ busca.dtdia.split('-').reverse().join('/') }}</span>
						<v-spacer />
						<v-img max-width="220" src="../../assets/icons/logo_min.png" />
						
					</v-card-title>
					<v-divider />
					<v-card-text class="black--text pt-5 mb-n3">
						<v-simple-table dense style="border: 1px solid #023449">
							<thead>
								<tr class="primary" style="height: 50px;">
									<th class="white--text text-subtitle-2">RK</th>
									<th class="white--text text-subtitle-2 pr-0">Filial</th>
                  <th class="white--text text-center text-subtitle-2 d-flex pl-2">
                    <!-- Tooltip para alternar a visibilidade da coluna -->
                    <v-tooltip color="blue-grey" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="float-left mt-1 mr-1" text fab x-small v-bind="attrs"
                               @click="mostrarColuna = !mostrarColuna" v-on="on">
                          <v-icon color="white" size="14">
                            {{ mostrarColuna ? 'mdi-eye-off' : 'mdi-eye' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="white--text">
												{{ mostrarColuna ? "Ocultar Venda (R$)" : "Mostrar Venda (R$)" }}
											</span>
                    </v-tooltip>
                    <span class="mt-1">Realizado diário</span>
                  </th>
									<v-fade-transition hide-on-leave>
										<th v-show="mostrarColuna" class="white--text text-center text-subtitle-2">
											Realizado Diário (R$)</th>
									</v-fade-transition>
									<th class="white--text text-center text-subtitle-2">Realizado acumulado</th>
                  <v-fade-transition hide-on-leave>
                    <th v-show="mostrarColuna" class="white--text text-center text-subtitle-2">
                      Realizado Acumulado (R$)</th>
                  </v-fade-transition>
									<th class="white--text text-center text-subtitle-2">Margem diária</th>
									<th class="white--text text-center text-subtitle-2">Margem acumulada</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(u, i) in rankingFat.Fatdre" :key="i" class="font-weight-bold">
									<td style="height: 23px !important;">{{ u.ranking }}</td>
									<td style="height: 23px !important;" class="text-truncate pr-0">{{ u.empresa }}</td>
									<td style="height: 23px !important;" :class="realizadoCor(u.realizadodiarioperc)"
										class="text-center pl-13">
										{{
											u.realizadodiarioperc.toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" style="height: 23px !important;" :class="realizadoCor(u.realizadodiarioperc)" class="text-center">
                      {{ u.vendadiaria | formataDinheiro }}</td>
                  </v-fade-transition>
									<td style="height: 23px !important;" :class="realizadoCor(u.realizadoacumuladoperc)"
										class="text-center">
										{{
											u.realizadoacumuladoperc.toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" style="height: 23px !important;" :class="realizadoCor(u.realizadoacumuladoperc)" class="text-center">
                      {{ u.vendaacumulada | formataDinheiro }}</td>
                  </v-fade-transition>
									<td style="height: 23px !important;" :class="margemCor(u.margemdiaria)"
										class="text-center">{{
											u.margemdiaria.toFixed(2) }} %</td>
									<td style="height: 23px !important;" :class="margemCor(u.margemacumulada)"
										class="text-center">{{
											u.margemacumulada.toFixed(2) }} %</td>
								</tr>
							</tbody>
							<tfoot v-if="rankingFat">
								<tr class="primary white--text font-weight-bold">
									<td></td>
									<td>TOTAL</td>
									<td class="text-center pl-13">{{ (rankingFat.totalrealizadodiarioperc).toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" class="text-center">{{ rankingFat.totalvendadiaria | formataDinheiro }}</td>
                  </v-fade-transition>
									<td class="text-center">{{ (rankingFat.totalrealizadoacumuladoperc).toFixed(2) }} %</td>
                  <v-fade-transition hide-on-leave>
                    <td v-show="mostrarColuna" class="text-center">{{ rankingFat.vendaacumulada | formataDinheiro }}</td>
                  </v-fade-transition>
									<td class="text-center">{{ (rankingFat.totalmargemdiaria).toFixed(2) }} %</td>
									<td class="text-center">{{ (rankingFat.totalmargemacumulada).toFixed(2) }} %</td>
									
								</tr>
							</tfoot>
						</v-simple-table>
						<span class="text-caption" style="display: block; text-align: end;">Data Processamento -  {{ rankingFat.dataprocessamento }}</span>
					</v-card-text>
					<v-divider />
					<v-card-actions class="pa-3">
						<v-btn :loading="carregando" block color="primary" elevation="0"
							@click="dialogFaturamento = false">Fechar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!-- FILTROS -->
			<v-col cols="12" md="12">
				<v-card>
					<v-card-text>
						<v-form>
							<v-row class="mb-n4">
								<v-col class="px-3">
									<InputDatePicker :disabled="carregando" v-model="busca.dtdia" label="Data"
										:outlined="true" :dense="true" />
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4 my-0 py-0">
						<small v-if="autoRefresh && !carregando" class="text--secondary text-caption">Próxima
							atualização em: {{ tempoRefresh }} segundos</small>
						<v-spacer />
						<span class="d-flex mb-n3">
							<v-switch :loading="carregando" v-model="autoRefresh" label="Atualização automática"
								color="primary" class="my-0 py-0"></v-switch>
						</span>
						<v-btn :loading="carregando" small color="primary" class="ml-3 mt-n3" elevation="0"
							@click="dialogVendas = true">
              Vendas
							<v-icon size="18" class="ml-2">mdi-magnify</v-icon>
						</v-btn>
						<v-btn :loading="carregando" small color="primary" class="ml-3 mt-n3" elevation="0"
							@click="dialogFaturamento = true">
              {{ $vuetify.breakpoint.smAndDown ? 'DRE' : 'Faturamento'}}
							<v-icon size="18" class="ml-2">mdi-magnify</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<!-- CARDS -->
			<v-col cols="12" md="12">
				<v-row class="pa-2">
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" :title="`Atingimento diário (Rede)`">
							<template v-if="dashboard.rankingfooter">{{
								dashboard.rankingfooter[dashboard.rankingfooter.length
									- 1].perrealizado.toFixed(2) }}%</template>
							<template v-else>0.00 %</template>
							<MenuInfo texto="Atingimento da venda realizada no dia em relação a meta de venda do dia" />
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" :title="`Atingimento acumulado (Rede)`">
							<template v-if="dashboard.rankingfooter">{{
								dashboard.rankingfooter[dashboard.rankingfooter.length
									- 1].perealizadoac.toFixed(2) }}%</template>
							<template v-else>0.00 %</template>
							<MenuInfo
								texto="Atingimento da venda realizada acumulada em relação a meta de venda acumuluda" />
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" title="Dias trabalhados">
							<template v-if="dashboard.diames">{{ dashboard.diames }}</template>
							<template v-else>0</template>
							<MenuInfo texto="Dias trabalhados (úteis) no mês" />
						</CardDashboard>
					</v-col>
				</v-row>
			</v-col>

			<!-- GRAFICOS -->
			<v-col cols="12" class="d-none d-md-block">
				<v-card>
					<v-card-title>
						Atingimento de venda diária sobre meta
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="!carregando">
						<v-row v-if="dashboard.rankingbody">
							<v-col>
								<GraficoBarDashboard :dataSeries="[
									{
										name: 'Porcentagem',
										data: dashboard.rankingbody.map((v) => v.perrealizado.toFixed(2)),
									},
								]" :categories="dashboard.rankingbody.map((v) => v.filial)" height="300" :money="false" :porcentagem="true"
									rotate="-50" :distributed="true" :dataLabels="{
										position: 'bottom',
										orientation: 'vertical',
										offsetY: 10,
									}" :color="corMod1(dashboard.rankingbody, 'perrealizado')" />
							</v-col>
						</v-row>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
					<v-card-text v-else>
						<v-skeleton-loader class="mx-auto" width="100%" height="350"
							type="image, image"></v-skeleton-loader>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-block d-md-none">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title>
						Atingimento de venda diária sobre meta
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300"
							type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<template v-if="dashboard.rankingbody">
							<v-simple-table dense>
								<tbody>
									<tr v-for="(u, i) in dashboard.rankingbody" :key="i">
										<td style="height: 22px" width="30%" class="text-truncate text-caption">
											{{ u.filial }}</td>
										<td style="height: 22px">
											<v-progress-linear rounded :value="u.perrealizado"
												:color="shadeColor('#002D56', 100 - u.perrealizado / 3)"
												class="white--text" height="17">
												<template v-slot:default="{ value }">
													<span class="text-caption">{{ value.toFixed(2) }}%</span>
												</template>
											</v-progress-linear>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</template>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-none d-md-block">
				<v-card>
					<v-card-title>
						Atingimento de venda acumuluda sobre meta
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="!carregando">
						<v-row v-if="dashboard.rankingbody">
							<v-col>
								<GraficoBarDashboard :dataSeries="[
									{
										name: 'Porcentagem',
										data: dashboard.rankingbody.map((v) =>
											v.perealizadoac.toFixed(2)
										),
									},
								]" :categories="dashboard.rankingbody.map((v) => v.filial)" height="300" :money="false" :porcentagem="true"
									rotate="-50" :distributed="true" :dataLabels="{
										position: 'bottom',
										orientation: 'vertical',
										offsetY: 10,
									}" :color="corMod1(dashboard.rankingbody, 'perealizadoac')" />
							</v-col>
						</v-row>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
					<v-card-text v-else>
						<v-skeleton-loader class="mx-auto" width="100%" height="350"
							type="image, image"></v-skeleton-loader>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-block d-md-none">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title>
						Atingimento de venda acumuluda sobre meta
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300"
							type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<template v-if="dashboard.rankingbody">
							<v-simple-table dense>
								<tbody>
									<tr v-for="(u, i) in dashboard.rankingbody" :key="i">
										<td style="height: 22px" width="30%" class="text-truncate text-caption">
											{{ u.filial }}</td>
										<td style="height: 22px">
											<v-progress-linear rounded :value="u.perealizadoac"
												:color="shadeColor('#002D56', 100 - u.perealizadoac / 3)"
												class="white--text" height="17">
												<template v-slot:default="{ value }">
													<span class="text-caption">{{ value.toFixed(2) }}%</span>
												</template>
											</v-progress-linear>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</template>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- PANELS -->
			<v-col cols="12">
				<v-expansion-panels v-model="panel" multiple>
					<v-expansion-panel active-class="primary white--text">
						<v-expansion-panel-header class="text-h6">
							Ranking diário de vendas
							<template v-slot:actions>
								<v-icon color="white" class="mr-n6">$expand</v-icon>
								<v-icon>$expand</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table v-if="dashboard.rankingbody" dense :headers="headerDiario" :items="diario"
								sort-by="perrealizado" :sort-desc="true" :items-per-page="28" hide-default-footer
								class="elevation-0" fixed-header height="70vh">
								<template slot="body.append">
									<tr class="d-none d-sm-table-row" v-for="(u, i) in dashboard.rankingfooter" :key="i"
										:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
										<td width="5%"></td>
										<td width="22%" class="font-weight-bold text-uppercase text-truncate">
											{{ u.regional == "Todas" ? `Total` : `Regional ${u.regional}` }}</td>
										<td width="15%" v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{ u.metadia | formataDinheiro }}</td>
										<td width="18%" v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{ u.realizadodia | formataDinheiro }}</td>
										<td width="18%" class="text-truncate font-weight-bold">
											<template v-if="u.perrealizado">{{ u.perrealizado.toFixed(2) }}</template>
											<template v-else>0.00</template>
										</td>
										<td width="22%" class="text-truncate font-weight-bold">
											<template v-if="u.margemdiaria">{{ u.margemdiaria.toFixed(2) }}</template>
											<template v-else>0.00</template>
										</td>
									</tr>
								</template>

								<template slot="body.append">
									<v-data-iterator class="d-flex d-sm-none mt-3" :items="dashboard.rankingbody.map((u) => {
										return {
											regional: u.regional,
											metadia: u.metadia,
											realizadodia: u.realizadodia,
											perrealizado: u.perrealizado,
											margemdiaria: u.margemdiaria,
										};
									})" disable-filtering disable-pagination disable-sort hide-default-footer>
										<v-row>
											<v-flex v-for="(u, i) in dashboard.rankingfooter" :key="i" cols="12"
												class="px-2 py-0">
												<v-card
													:class="u.regional == 'Todas' ? 'blue-grey lighten-3' : 'blue lighten-3'">
													<v-card-title class="subheading font-weight-bold">{{ u.regional ==
														"Todas" ? `Total` : `Regional ${u.regional}` }}</v-card-title>
													<v-divider />
													<v-list dense
														:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">META
																DIÁRIA</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.metadia | formataDinheiro
																	}}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">REALIZADO
																DIÁRIO</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.realizadodia | formataDinheiro
																	}}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">REALIZADO
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.perrealizado">{{
																		u.perrealizado.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">MARGEM DIÁRIA
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.margemdiaria">{{
																		u.margemdiaria.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
													</v-list>
												</v-card>
											</v-flex>
										</v-row>
									</v-data-iterator>
								</template>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel active-class="primary white--text">
						<v-expansion-panel-header class="text-h6">
							Ranking diário de vendas (acumulado)
							<template v-slot:actions>
								<v-icon color="white" class="mr-n6">$expand</v-icon>
								<v-icon>$expand</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table v-if="dashboard.rankingbody" dense :headers="headerAcumulado" :items="dashboard.rankingbody.map((u) => {
								return {
									rk: u.rk,
									filial: u.filial,
									metaacumuladaac: parseFloat(u.metaacumuladaac).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', }),
									realizadoacumuladoac: parseFloat(u.realizadoacumuladoac).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', }),
									perealizadoac: parseFloat(u.perealizadoac).toFixed(2),
									margemacumuladaac: parseFloat(u.margemacumuladaac).toFixed(2),
								};
							})" sort-by="perealizadoac" :sort-desc="true" :items-per-page="28" hide-default-footer class="elevation-0"
								fixed-header height="70vh">
								<template slot="body.append">
									<tr class="d-none d-sm-table-row" v-for="(u, i) in dashboard.rankingfooter" :key="i"
										:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
										<td width="5%"></td>
										<td width="22%" class="font-weight-bold text-uppercase text-truncate">{{
											u.regional == "Todas" ? `Total` : `Regional ${u.regional}` }}</td>
										<td width="15%" v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{ u.metaacumuladaac | formataDinheiro }}</td>
										<td width="18%" v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{ u.realizadoacumuladoac | formataDinheiro }}</td>
										<td width="18%" class="text-truncate font-weight-bold">
											<template v-if="u.perealizadoac">{{ u.perealizadoac.toFixed(2) }}</template>
											<template v-else>0.00</template>
										</td>
										<td width="22%" class="text-truncate font-weight-bold">
											<template v-if="u.margemacumuladaac">{{ u.margemacumuladaac.toFixed(2)
												}}</template>
											<template v-else>0.00</template>
										</td>
									</tr>
								</template>

								<template slot="body.append">
									<v-data-iterator class="d-flex d-sm-none mt-3" :items="dashboard.rankingbody.map((u) => {
										return {
											regional: u.regional,
											metaacumuladaac: u.metaacumuladaac,
											realizadoacumuladoac: u.realizadoacumuladoac,
											perealizadoac: u.perealizadoac,
											margemacumuladaac: u.margemacumuladaac,
										};
									})" disable-filtering disable-pagination disable-sort hide-default-footer>
										<v-row>
											<v-flex v-for="(u, i) in dashboard.rankingfooter" :key="i" cols="12"
												class="px-2 py-0">
												<v-card
													:class="u.regional == 'Todas' ? 'blue-grey lighten-3' : 'blue lighten-3'">
													<v-card-title class="subheading font-weight-bold">{{ u.regional ==
														"Todas" ? `Total` : `Regional ${u.regional}` }}</v-card-title>
													<v-divider />
													<v-list dense
														:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">META
																ACUMULADA</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.metaacumuladaac |
																	formataDinheiro }}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">REALIZADO
																ACUMULADO</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.realizadoacumuladoac |
																	formataDinheiro }}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">REALIZADO
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.perrealizado">{{
																		u.perealizadoac.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">MARGEM ACUMULADA
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.margemdiaria">{{
																		u.margemacumuladaac.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
													</v-list>
												</v-card>
											</v-flex>
										</v-row>
									</v-data-iterator>
								</template>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel active-class="primary white--text">
						<v-expansion-panel-header class="text-h6">
							Ranking diário de vendas (projeção)
							<template v-slot:actions>
								<v-icon color="white" class="mr-n6">$expand</v-icon>
								<v-icon>$expand</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table v-if="dashboard.rankingbody" dense :headers="headerProjecao" :items="dashboard.rankingbody.map((u) => {
								const obj = {};
								(obj.rk = u.rk), (obj.filial = u.filial);
								obj.metamensal = parseFloat(u.metamensal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
								obj.projecaoreal = parseFloat(u.projecaoreal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
								obj.projecaoatingida = `${u.projecaoatingida.toFixed(2)}`;
								obj.vendaanoanterior = parseFloat(u.vendaanoanterior).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', });
								obj.variacao = `${u.variacao.toFixed(2)}`;
								obj.vendamesanterior = parseFloat(u.vendamesanterior).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', });
								obj.variacaomesanterior = `${u.variacaomesanterior.toFixed(2)}`;
								obj.crescimentoorcado = `${u.crescimentoorcado.toFixed(2)}`;
								obj.margemacumuladaac = `${parseFloat(u.margemacumuladaac).toFixed(2)}`;
								return obj;
							})" sort-by="projecaoatingida" :sort-desc="true" :items-per-page="28" hide-default-footer class="elevation-0"
								fixed-header height="70vh">
								<template slot="body.append">
									<tr class="d-none d-sm-table-row" mr-5 v-for="(u, i) in dashboard.rankingfooter"
										:key="i"
										:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
										<td></td>
										<td class="font-weight-bold text-uppercase text-truncate">{{ u.regional ==
											"Todas" ? `Total` : `Regional ${u.regional}` }}</td>
										<td v-if="permissao('dashboard_ranking_vendas_valores')" pl-9
											class="font-weight-bold">{{ u.metamensal | formataDinheiro }}</td>
										<td v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{ u.projecaoreal | formataDinheiro }}</td>
										<td class="text-truncate font-weight-bold">
											<template v-if="u.projecaoatingida">{{ u.projecaoatingida.toFixed(2)
												}}</template>
											<template v-else>0.00</template>
										</td>
										<td v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{
												u.vendaanoanterior | formataDinheiro }}</td>
										<td class="text-truncate font-weight-bold">
											<template v-if="u.variacao">{{ u.variacao.toFixed(2) }}</template>
											<template v-else>0.00</template>
										</td>
										<td v-if="permissao('dashboard_ranking_vendas_valores')"
											class="font-weight-bold">{{
												u.vendamesanterior | formataDinheiro }}</td>
										<td class="text-truncate font-weight-bold">
											<template v-if="u.variacaomesanterior">{{ u.variacaomesanterior.toFixed(2)
												}}</template>
											<template v-else>0.00</template>
										</td>
										<td class="text-truncate font-weight-bold">
											<template v-if="u.crescimentoorcado">{{ u.crescimentoorcado.toFixed(2)
												}}</template>
											<template v-else>0.00</template>
										</td>
										<td class="text-truncate font-weight-bold">
											<template v-if="u.margemacumuladaac">{{ u.margemacumuladaac.toFixed(2)
												}}</template>
											<template v-else>0.00</template>
										</td>
									</tr>
								</template>

								<template slot="body.append">
									<v-data-iterator class="d-flex d-sm-none mt-3" :items="dashboard.rankingbody.map((u) => {
										return {
											regional: u.regional,
											metamensal: u.metamensal,
											projecaoreal: u.projecaoreal,
											projecaoatingida: u.projecaoatingida,
											vendaanoanterior: u.vendaanoanterior,
											vendamesanterior: u.vendamesanterior,
											variacaomesanterior: u.variacaomesanterior,
											crescimentoorcado: u.crescimentoorcado,
										};
									})" disable-filtering disable-pagination disable-sort hide-default-footer>
										<v-row>
											<v-flex v-for="(u, i) in dashboard.rankingfooter" :key="i" cols="12"
												class="px-2 py-0">
												<v-card
													:class="u.regional == 'Todas' ? 'blue-grey lighten-3' : 'blue lighten-3'">
													<v-card-title class="subheading font-weight-bold">{{ u.regional ==
														"Todas" ? `Total` : `Regional ${u.regional}` }}</v-card-title>
													<v-divider />
													<v-list dense
														:class="u.regional == 'Todas' ? 'blue-grey lighten-4' : 'blue lighten-4'">
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">META
																MENSAL</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.metamensal | formataDinheiro
																	}}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">PROJEÇÃO
																REAL</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>{{ u.projecaoreal | formataDinheiro
																	}}</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">PROJEÇÃO ATINGIDA
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.projecaoatingida">{{
																		u.projecaoatingida.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">{{
																	`${dashboard.descrmesatual.substr(0,
																		3)}/${busca.dtdia.split("-")[0] - 1}`
																}}</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.vendaanoanterior">{{
																		u.vendaanoanterior | formataDinheiro
																	}}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">{{
																	`${dashboard.mespassado.substr(0,
																		3)}/${busca.dtdia.split("-")[0]}`
																}}</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.vendamesanterior">{{
																		u.vendamesanterior.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">{{ `VAR.
																${dashboard.mespassado.substr(0,
																	3)}x${dashboard.descrmesatual.substr(0, 3)} (%)`
																}}</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.variacaomesanterior">{{
																		u.variacaomesanterior | formataDinheiro
																	}}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">CRESCIMENTO
																ORÇADO
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.crescimentoorcado">{{
																		u.crescimentoorcado.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
														<v-list-item>
															<v-list-item-content
																class="text-truncate font-weight-bold">MARGEM ACUMULADA
																(%)</v-list-item-content>
															<div class="justify-content-end">
																<v-list-item-content>
																	<template v-if="u.margemdiaria">{{
																		u.margemacumuladaac.toFixed(2) }}</template>
																	<template v-else>0.00</template>
																</v-list-item-content>
															</div>
														</v-list-item>
													</v-list>
												</v-card>
											</v-flex>
										</v-row>
									</v-data-iterator>
								</template>
							</v-data-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
export default {
	name: "DashboardRankingVendas",
	components: {
		InputDatePicker,
		GraficoBarDashboard,
		CardDashboard,
		MenuInfo,
	},
	data: () => ({
		dialogVendas: false,
		dialogFaturamento: false,
		mostrarColuna: false,
		dialogDados: {},
		headerDiario: [],
		headerAcumulado: [],
		headerProjecao: [],
		diario: [],
		tempoRefresh: 60,
		autoRefresh: false,
		dashboard: { mespassado: " - ", descrmesatual: " - " },
		rankingFat: [],
		panel: [],
		carregando: false,
		busca: {
			dtdia: new Date().toISOString().split("T")[0],
		},
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		get() {
			this.dashboard = { mespassado: " - ", descrmesatual: " - " };
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/ranking`, {
					data: this.busca.dtdia,
				})
				.then((res) => {
					this.dashboard = res.data;
					let cont = 1;
					this.dashboard.rankingbody.forEach((v) => {
						v.rk = cont;
						cont++;
					});
					this.carregando = false;
					this.dialogDados = this.dashboard.rankingbody
						.sort((a, b) => b.perealizadoac - a.perealizadoac)
						.map((v) => {
							return {
								filial: v.filial,
								perrealizado: v.perrealizado,
								perealizadoac: v.perealizadoac,
								margemdiaria: v.margemdiaria,
								margemacumuladaac: v.margemacumuladaac,
								realizadodia: v.realizadodia,
                realizadoacumuladoac: v.realizadoacumuladoac,
                variacao: v.variacao,
							};
						});
					this.diario = this.dashboard.rankingbody
						.sort((a, b) => b.perrealizado - a.perrealizado)
						.map((u, i) => {
							return {
								rk: i + 1,
								filial: u.filial,
								metadia: parseFloat(u.metadia).toLocaleString(
									"pt-br",
									{
										style: "currency",
										currency: "BRL",
									}
								),
								realizadodia: parseFloat(
									u.realizadodia
								).toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								}),
								perrealizado: parseFloat(
									u.perrealizado
								).toFixed(2),
								margemdiaria: parseFloat(
									u.margemdiaria
								).toFixed(2),
							};
						});
					if (this.autoRefresh) {
						this.tempoRefresh = 60;
						setTimeout(() => {
							this.get();
						}, 60000);
					}
					this.montaHeaderDiario();
					this.montaHeaderAcumulado();
					this.montaHeaderProjecao();
					this.montaHeaderGrupoGerencial();
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listaIndicesFat(){
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/ranking/faturamento/dre/listar`, {
					data: this.busca.dtdia,
				})
				.then((res) => {
					this.rankingFat = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		timerRefresh() {
			if (this.tempoRefresh < 0) {
				this.tempoRefresh = 60;
			}
			setTimeout(() => {
				this.tempoRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		async init() {
			if (new Date(this.busca.dtdia).getDay() == 6) {
				this.busca.dtdia = `${new Date(new Date().setHours(-25))
					.toLocaleDateString("pt-BR")
					.split("/")
					.reverse()
					.join("-")}`;
			} else {
				await this.get();
				await this.listaIndicesFat();
			}
			this.timerRefresh();
		},
		montaHeaderDiario() {
			if (this.permissao("dashboard_ranking_vendas_valores")) {
				this.headerDiario = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{ text: "META DIÁRIA", value: "metadia", sortable: false },
					{
						text: "REALIZADO DIÁRIO",
						value: "realizadodia",
						sortable: false,
					},
					{ text: "REALIZADO (%)", value: "perrealizado" },
					{
						text: "MARGEM DIÁRIA (%)",
						value: "margemdiaria",
						sortable: false,
					},
				];
			} else {
				this.headerDiario = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{ text: "REALIZADO (%)", value: "perrealizado" },
					{
						text: "MARGEM DIÁRIA (%)",
						value: "margemdiaria",
						sortable: false,
					},
				];
			}
		},
		montaHeaderAcumulado() {
			if (this.permissao("dashboard_ranking_vendas_valores")) {
				this.headerAcumulado = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{
						text: "META ACUMULADA",
						value: "metaacumuladaac",
						sortable: false,
					},
					{
						text: "REALIZADO ACUMULADO",
						value: "realizadoacumuladoac",
						sortable: false,
					},
					{ text: "REALIZADO (%)", value: "perealizadoac" },
					{
						text: "MARGEM ACUMULADA (%)",
						value: "margemacumuladaac",
						sortable: false,
					},
				];
			} else {
				this.headerAcumulado = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{ text: "REALIZADO (%)", value: "perealizadoac" },
					{
						text: "MARGEM ACUMULADA (%)",
						value: "margemacumuladaac",
						sortable: false,
					},
				];
			}
		},
		montaHeaderProjecao() {
			if (this.permissao("dashboard_ranking_vendas_valores")) {
				this.headerProjecao = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{
						text: "META MENSAL",
						value: "metamensal",
						sortable: false,
					},
					{
						text: "PROJEÇÃO REAL",
						value: "projecaoreal",
						sortable: false,
					},
					{
						text: "PROJEÇÃO ATINGIDA (%)",
						value: "projecaoatingida",
					},
					{
						text: `${this.dashboard.descrmesatual.substr(0, 3)}/${this.busca.dtdia.split("-")[0] - 1
							}`,
						value: "vendaanoanterior",
						sortable: false,
					},
					{
						text: "VAR. REAL (%)",
						value: "variacao",
						sortable: false,
					},
					{
						text: `${this.dashboard.mespassado.substr(0, 3)}/${this.busca.dtdia.split("-")[0]
							}`,
						value: "vendamesanterior",
						sortable: false,
					},
					{
						text: `VAR. ${this.dashboard.mespassado.substr(
							0,
							3
						)}x${this.dashboard.descrmesatual.substr(0, 3)} (%)`,
						value: "variacaomesanterior",
						sortable: false,
					},
					{
						text: "CRESCIMENTO ORÇADO (%)",
						value: "crescimentoorcado",
						sortable: false,
					},
					{
						text: "MARGEM ACUMULADA (%)",
						value: "margemacumuladaac",
						sortable: false,
					},
				];
			} else {
				this.headerProjecao = [
					{ text: "RK", value: "rk", sortable: false },
					{ text: "FILIAL", value: "filial", sortable: false },
					{
						text: "PROJEÇÃO ATINGIDA (%)",
						value: "projecaoatingida",
					},
					{
						text: "VAR. REAL (%)",
						value: "variacao",
						sortable: false,
					},
					{
						text: `VAR. ${this.dashboard.mespassado.substr(
							0,
							3
						)}x${this.dashboard.descrmesatual.substr(0, 3)} (%)`,
						value: "variacaomesanterior",
						sortable: false,
					},
					{
						text: "CRESCIMENTO ORÇADO (%)",
						value: "crescimentoorcado",
						sortable: false,
					},
				];
			}
		},
		margemCor(valor) {
			if (valor <= 24.50) {
				return "red--text text--darken-4";
			} else if (valor >= 24.51 && valor <= 25.99) {
				return "green--text text--darken-4";
			} else {
				return "blue--text text--darken-4";
			}
		},
    variacaoCor(valor) {
      if (valor < 0) {
        return "red--text text--darken-4";
      } else if (valor > 0) {
        return "blue--text text--darken-4";
      } else {
        return "black--text";
      }
    },
		realizadoCor(valor) {
			if (valor >= 100) {
				return "blue--text text--darken-4";
			}
		},
		corMod1(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor;
				cor = this.corSemafaro(v[campo]);
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corMod2(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor = "#002D56";
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
	},
	watch: {
		"busca.dtdia": function () {
			this.get();
			this.listaIndicesFat();
		},
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.tempoRefresh = 60;
				this.get();
				this.listaIndicesFat();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style>
.v-dialog {
	max-height: 97% !important;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

